<template>
  <div class="login wrapper" id="login">
    <div class="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// const index = require('@/assets/images/index.png');

export default {
  name: "wrapper",
  data() {
    return {}
  },
  methods: {},
  mounted() {
    VANTA.NET({
      el: "#login",
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 200.00,
      minWidth: 200.00,
      scale: 1.00,
      scaleMobile: 1.00,
      color: 0x248449,
      backgroundColor: 0xe9e9eb
    })
  }
}
</script>

<style lang="scss">
@import '../../style/login/login';
</style>
