<template>
  <div class="main">
    <div class="payments">
      <div class="item" v-for="(item, i) in items" :key="i">
        <img :src="item.icon" alt="" />
        <div class="item-title" v-text="item.name"></div>
        <div class="item-btn btn" v-text="item.cost + '$ / month'"></div>
        <div class="item-desc">
          <ul>
            <li v-for="(t,ix) in item.desc" :key="ix" v-text="t"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VVIP from "@/assets/images/VVIP.png";
import VVVIP from "@/assets/images/VVVIP.png";

export default {
  name: "Payment",
  data() {
    return {
      url: "",
      dcid: this.$root.dcid,
      items: [
        {
          name: "VIP", icon: VIP, cost: 5,
          desc: [
            '看直播時獲得的牙齒加成 + 3%',
            '[虎杖生哥] 每月可以免費 1 次，並可以選擇額外1種背景',
            '[麻將賓果] 每格可以重新三選一 1 次',
            '[每求必硬] 每日可以重抽 1 次',
            '[這牌聽什麼大挑戰] 每日可以多挑戰 1 次',
            '[將KENKBALL猜猜拳] 每日可以多輸 1 次'
          ]
        },
        {
          name: "VVIP", icon: VVIP, cost: 15,
          desc: [
            '看直播時獲得的牙齒加成 + 8%',
            '[虎杖生哥] 每月可以免費 5 次，並可以選擇額外3種背景',
            '[麻將賓果] 每格可以重新三選一 1 次',
            '[每求必硬] 每日可以重抽 1 次',
            '[這牌聽什麼大挑戰] 每日可以多挑戰 2 次',
            '[將KENKBALL猜猜拳] 每日可以多輸 2 次'
          ]
        },
        {
          name: "VVVIP", icon: VVVIP, cost: 35,
          desc: [
            '看直播時獲得的牙齒加成 + 20%',
            '[虎杖生哥] 每月可以免費 5 次，並可以選擇全部背景',
            '[麻將賓果] 每格可以重新三選一 1 次',
            '[每求必硬] 每日可以重抽 1 次',
            '[這牌聽什麼大挑戰] 每日可以多挑戰 4 次',
            '[將KENKBALL猜猜拳] 每日可以多輸 4 次'
          ]
        },
      ],
    };
  },
  methods: {
    bindAccount: function () {
      this.$store
        .dispatch("bindGooleAccount", { credentials: this.dcid })
        .then((res) => {
          if (!res.errorCode) {
            let url = res.data.url;
            let _config = "height=660,width=600,toolbar=no";
            window.open(url, "Google Auth Login", _config);
          }
        });
    },
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.payments {
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 5rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  .item {
    box-sizing: border-box;
    height: auto;
    transition: all 300ms cubic-bezier(0.19, 1, 0.22, 1) 0s;
    padding: 0.75rem;
    margin: 0;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1),
      0px 0px 1px 0px rgba(0, 0, 0, 0.1);

    @media (max-width: 1200px) and (min-width: 768px) {
      width: 60%;
      margin: 0 auto 4rem;
    }

    @media (max-width: 768px) {
      width: 85%;
      margin: 0 auto 4rem;
    }

    .item-title {
      font-size: 1.5rem;
    }


    .item-desc {
      text-align: justify;
      padding: 0 1.2rem;
      font-size: .95rem;

      ul {
        padding: .8rem;
        list-style: circle;

        li {
          margin-bottom: 4px;
        }
      }
    }
  }
}
</style>
