<template>
  <div class="top-navigator" :class="[!topFixed?'nav-empty':'',]">
    <div class="scroll-navi" :class="topFixed?'active':''">
      <!-- <div class="logo"></div> -->
      <div class="touch-dropdown">
        <div class="dropdown">
          <div>
            <div class="dropdown-main">GAMES
              <div>
                <div></div>
              </div>
            </div>
            <div class="options">
              <div class=""><a href="/en/" class="">HOME</a>
                <div></div>
              </div>
              <div class="disable"><a href="/en/games/home" class="">GAMES</a>
                <div></div>
              </div>
              <div class=""><a href="/en/news/" class="">NEWS</a>
                <div></div>
              </div>
              <div class=""><a href="/en/company/" class="">COMPANY</a>
                <div></div>
              </div>
              <div class=""><a href="/en/events/2019/" class="">EVENTS</a>
                <div></div>
              </div>
              <div class=""><a href="/en/partners/" class="">PARTNERS</a>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg"></div>
    </div>
    <div class="mob-nav" :class="topFixed?'scrolled':''">
      <div class="mob-overlay"></div>
      <!-- <div class="mob-logo">
        <router-link :to="{ name: 'Home'}">
          <img :src="topFixed?weblogo_b:weblogo_w" class="white">
        </router-link>
      </div> -->
      <div class="mob-toggle">
        <div class="toggle-cover" @click="mobNavActive= true"><span></span><span></span><span></span><span></span><span></span></div>
      </div>
    </div>
    <div class="mobscroll-nav" :class="topFixed?'scrolled':''">
      <div class="mob-cover">
        <!-- <div class="mob-logo"></div> -->
        <div class="mob-toggle">
          <div class="toggle-cover"><span></span><span></span><span></span></div>
        </div>
      </div>
      <div class="mob-overlay"></div>
    </div>
    <div class="main-navi" :class="topFixed?'active':''">
      <!-- <div class="logo-left">
        <router-link :to="{ name: 'Home'}" :class="'nuxt-link-active'">
          <img :src="topFixed?weblogo_b:weblogo_w">
        </router-link>
      </div> -->
      <div class="menu-right">
        <div class="menu">
          <div class="upper">
            <router-link :to="{ name: 'index'}">
              Home
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="nav-enquiry" :class="[miniGmaeMenu?'active':'']">
      <div class="content" v-if="miniGmaeMenu">
        <div class="close" @click="toggleGameMenu">
          <svgIcon :type="'close'"></svgIcon>
        </div>
        <div class="main container">
          <div class="row" v-for="(item,i) in $dataManager.getters.games" :key="i">
            <div class="title upper" v-text="item.name"></div>
            <div class="games">
              <div class="game" v-for="(game,j) in item.games" :key="j">
                <router-link :to="{ name: item.type,params:{game:game.name}}">
                  <div class="icon">
                    <img :src="gameIcon(game)" alt="">
                  </div>
                  <div class="name">
                    <span class="upper" v-text="game.name"></span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="nav-slide" :class="[topFixed?'scrolled':'',mobNavActive?'active':'']">
      <div class="slide-bg">
        <div></div>
      </div>
      <div class="slide-banner">
        <!-- <div class="pg-logo"><img :src="weblogo_b"></div> -->
        <div class="close-btn">
          <div class="btn-bg"></div>
          <div class="btn-cover" @click="mobNavActive= false"><span></span><span></span></div>
        </div>
      </div>
      <div class="slide-col down">
        <div class="col-menu">
          <div>
            <div class="item">
              <router-link :to="{ name: 'index'}">
                首頁
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import svgIcon from '@/components/layout/SvgIcon'

const weblogo_w = require('@/components/layout/header/weblogo.png')
const weblogo_b = require('@/components/layout/header/weblogob.png')
export default {
  name: "topHeader",
  components: {svgIcon},
  props: ['headerClass', 'scrolled', 'scrollNum'],
  data: function () {
    return {
      weblogo_w: weblogo_w,
      weblogo_b: weblogo_b,
      mobNavActive: false,
      miniGmaeMenu: false,
      mobMenu: {
        miniGameMobMenu: false,
        marbleGameMobMenu: false,
        lottoGameMobMenu: false,
      },

    }
  },
  computed: {
    topFixed() {
      return this.scrolled || this.miniGmaeMenu
    },
    route() {
      return this.$route.name
    }
  },
  methods: {
    // gameIcon: function (game) {
    //   var url = 'images/game/' + game.name + '/mini_icon.png';
    //   return url
    // },
  },
  watch: {
    scrollNum() {
      this.miniGmaeMenu = false
    },
  }
}
</script>

<style lang="scss" scoped>
@import 'topHeader';
</style>
