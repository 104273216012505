<template>
  <div class="footer">
    <div class="content">
      <ul class="link">
        <li>
          <a href="https://linktr.ee/boyplaymj" target="_blank">About Us / 關於我們</a>
        </li>
        <li>
          <router-link :to="{ name: 'policy' }">
            Privacy policy / 條款與細則
          </router-link>
        </li>
      </ul>
      <ul class="info">
        <li class="copyright">工口娛樂有限公司 SEXIT Entertainment crop.</li>
        <li class="copyright">聯絡信箱 : sawg.gameboy@gmail.com</li>
        <li class="copyright">聯絡電話 : 0937694641</li>
        <li class="copyright">
          © 2022 boyplaymj. All Rights Reserved.
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    //todo:: iphone ->window.location.href
    bindAccount: function () {
      this.$store
        .dispatch("bindGooleAccount", { credentials: this.dcid })
        .then((res) => {
          if (!res.errorCode) {
            let url = res.data.url;
            let _config = "height=660,width=600,toolbar=no";
            if (this.userAgent === "iOS") {
              window.location.href = url;
            } else {
              window.open(url, "Google Auth Login", _config);
            }
          }
        });
    },
  },
  mounted() { },
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  bottom: 0;
  height: auto;
  width: 100%;

  .content {
    margin: 0 auto;
    max-width: 72rem;
    width: 100%;
    text-align: center;

    ul {
      list-style: none;
      padding: 0;

      &.link {
        display: flex;
        justify-content: center;

        li {
          padding: 6px;
        }
      }

      &.info {
        li {
          margin: .5rem 0 0;
        }
      }

      a {
        text-decoration: none;
        color: #2b4f66;
      }
    }
  }
}
</style>
