import Vue from 'vue'
import VueRouter from 'vue-router'
import vanta from '@/components/login/wrapper'
import wrapper from '@/components/member/wrapper'
import bind from '@/components/login/bind'
import payment from '@/components/member/payment'
import policy from '@/components/member/policy'

// import sml from '@/components/sml'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: wrapper,
        children: [
            {
                path: '/',
                name: 'index',
                component: payment
            },
            {
                path: '/policy',
                name: 'policy',
                component: policy
            },
        ]
    },
    {
        path: '/',
        name: 'Home',
        component: vanta,
        children: [
            {
                path: 'bind',
                name: 'bind',
                component: bind
            },
        ]
    },
]

const router = new VueRouter({
    routes,

})
export default router
