<template>
  <div class="wrapper">
    <topHeader></topHeader>
    <div
      class="banner"
      :style="{ backgroundImage: 'url(' + banner + ')' }"
    ></div>
    <div class="member">
      <div class="content">
        <router-view></router-view>
      </div>
    </div>
    <bFooter></bFooter>
  </div>
</template>

<script>
import webBanner from "@/assets/images/sweetbot-webbanner.jpg";
import mobileBanner from "@/assets/images/sweetbot-mobilebanner.png";
import topHeader from "@/components/layout/header/topHeader";
import bFooter from "@/components/layout/footer/footer";

export default {
  name: "wrapper",
  components: { topHeader, bFooter },
  data() {
    return {
      screenWidth: 0,
    };
  },
  computed: {
    banner() {
      return this.screenWidth > 992 ? webBanner : mobileBanner;
    },
  },
  methods: {},
  mounted() {
    var $this = this
    $this.screenWidth = window.innerWidth
    window.onresize = () => {
      return (() => {
        $this.screenWidth = window.innerWidth
      })()
    }
  },
  watch: {
    $route() {
      this.$bus.$emit("scrollTop");
    },
  },
};
</script>

<style lang="scss">
@import "../../style/member/member";
.wrapper {
  position: relative;
  .banner {
    height: 80vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 10%;
  }
  .member {
    display: grid;
    margin: 0 auto;
    max-width: 72rem;
    width: 100%;
    .content {
      padding: 6rem 0 10rem;
    }
  }
}
</style>
